// =========== Production Environment =================================

// TODO: adjust for new CDK'ed Cognito
const awsmobileProd = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:e96eabe4-3dc1-4eec-a1a7-f44228853734",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_23L6vLwSg",
  aws_user_pools_web_client_id: "79ib9vnnin203krpe2iofuqcrc",
  oauth: {},
  aws_cognito_login_mechanisms: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket_region: "ap-southeast-1",
};

// =========== DEV Environment ==================================

const awsmobileDev = {
  aws_project_region: "eu-north-1",
  aws_cognito_region: "eu-north-1",
  aws_user_pools_id: "eu-north-1_YvhwmjarA",
  aws_user_pools_web_client_id: "2jm16jt0u1f8ae19qriaf1g0q4",
  aws_cognito_identity_pool_id:
    "eu-north-1:68e7187c-d49a-49e4-8c81-d247771d727b",
  aws_user_files_s3_bucket_region: "eu-north-1",
};

const currentConfig =
  process.env.REACT_APP_NODE_ENV === "production"
    ? awsmobileProd
    : awsmobileDev;

export default currentConfig;
