import { atom } from "recoil";
import { BrandDataDTO } from "./api-client";

const brandAtom = atom<BrandDataDTO>({
  key: "brandAtom",
  default: undefined,
});

const loaderAtom = atom<boolean>({
  key: "loaderAtom",
  default: true,
});

const loggedInUser = atom<any>({
  key: "loggedInUser",
  default: undefined,
});

export { brandAtom, loaderAtom, loggedInUser };
