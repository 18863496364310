import React from "react";
import { Image } from "@reamrd/styledoubler";
import classNames from "classnames";

const laptopImageDark = require("../assets/images/laptop-dark.png");
const laptopImageLight = require("../assets/images/laptop-light.png");

const AuthLogo = ({ lightPage }: { lightPage?: boolean }) => {
  const logoUrl = `https://assets.styledoubler.com/images/misc/${
    lightPage ? "black" : "white"
  }-logo-full.svg`;

  return (
    <div
      className={classNames({
        "w-1/2 flex flex-col justify-center items-center gap-10": true,
        "bg-black": !lightPage,
        "bg-[#F9F9F9]": lightPage,
      })}
    >
      <img alt="" className="w-[220px] h-[31px]" src={logoUrl} />

      <Image
        className="w-[600px] h-[600px] object-cover"
        imageUrl={lightPage ? laptopImageLight : laptopImageDark}
      />
    </div>
  );
};

export default AuthLogo;
