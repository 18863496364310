import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { RecoilRoot, useRecoilState } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import config from "./aws-exports";
import { loaderAtom, loggedInUser } from "./atoms";
import MasterWelcome from "./pages/MasterWelcome";
import { Toaster } from "@reamrd/styledoubler";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import Loader from "./components/shared/Loader";
import { match } from "ts-pattern";

Amplify.configure(config);

const AuthCheck = () => {
  const [user, userSet] = useRecoilState(loggedInUser);
  const [isLoading, isLoadingSet] = useRecoilState(loaderAtom);

  useEffect(() => {
    const checkAuth = async () => {
      isLoadingSet(true);
      try {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

        userSet(currentAuthenticatedUser.attributes.email);
      } catch (error) {
        userSet("");
      }
      isLoadingSet(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return user ? <App /> : <MasterWelcome />;
};

// ReactDOM.render(
//   <React.StrictMode>
//     <AmplifyProvider>
//       <Authenticator.Provider>
//         <RecoilRoot>
//           <BrowserRouter>
//             <AuthCheck />
//             <Toaster position="top-center" />
//           </BrowserRouter>
//         </RecoilRoot>
//       </Authenticator.Provider>
//     </AmplifyProvider>
//   </React.StrictMode>,

//   document.getElementById("root")
// );

const RenderIframe = () => {
  const search = document.location.search;
  const path = document.location.pathname;

  if (!search) {
    return <></>;
  }

  const getHostname = () =>
    match(true)
      .with(window.location.hostname.includes("brands"), () =>
        window.location.hostname.replace("brands", "managers")
      )
      .otherwise(
        () => `${window.location.protocol}//${window.location.hostname}:5173`
      );

  return (
    <iframe
      className="w-full h-full"
      src={`${getHostname()}${path}${search}`}
    />
  );
};
ReactDOM.render(<RenderIframe />, document.getElementById("root"));
