import { Storage } from "aws-amplify";
import { appConfiguration } from "./app-configuration";

type Progress = {
  uploaded: number;
  total: number;
};

Storage.configure({
  region: appConfiguration.region,
});

const uploadFile = async (
  key: string,
  file: File | Blob,
  bucket: string,
  setUploadingProgress?: (progress: Progress) => void,
  ignoreAccessModifier = true
) => {
  const result = await Storage.put(key, file, {
    progressCallback(progress) {
      !!setUploadingProgress &&
        setUploadingProgress({
          uploaded: progress.loaded,
          total: progress.total,
        });
    },

    level: "public",
    customPrefix: ignoreAccessModifier ? { public: "" } : undefined,
    bucket,
  });

  return result.key;
};

export { uploadFile };
