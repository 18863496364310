import { Auth } from "aws-amplify";
import { ApiClient } from "./api-client";
import { appConfiguration } from "./utils/app-configuration";

let token: string;

const getToken = async () =>
  token
    ? token
    : (await Auth.currentAuthenticatedUser()).signInUserSession.idToken
      .jwtToken;

const fetchWithAuthOverride = async (url: string, options: any) =>
  fetch(url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getToken()}`,
    },
  });

const apiBasePath = appConfiguration.apiEndpoint;

const commonClientProps = {
  fetch: fetchWithAuthOverride,
};

const client = {
  campaigns: new ApiClient(apiBasePath, commonClientProps),
};

export default client;
